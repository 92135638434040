<template>
  <div class="nav_wrapper  w">
    <NuxtLink
      class="nav_logo"
      to="/"
    >
      <img
        v-if="componentInfo?.websiteLogo"
        :src="componentInfo.websiteLogo"
      >
    </NuxtLink>
    <ul
      v-show="componentInfo.contentList && componentInfo.contentList.length"
      class="nav_list_wrapper"
    >
      <template v-for="(navItem, index) in componentInfo.contentList">
        <custom-link
          v-if="(index < showNum || componentInfo.contentList.length === showNum) && navItem.status"
          :key="navItem.id"
          :to="handleNavigate(navItem)"
          :target-type="navItem.targetType"
          :target-open-new-page="navItem.targetOpenNewPage"
        >
          <li
            class="nav_item"
            :class="{ active: index === componentInfo.activeIndex }"
          >
            {{ navItem.navName }}
          </li>
        </custom-link>
      </template>
      <client-only>
        <el-popover
          v-if="componentInfo.contentList.length > showNum"
          placement="bottom"
          trigger="hover"
          popper-class="nav_popover"
          :width="116"
          @show="showheadMoreBg()"
          @hide="hideheadMoreBg()"
        >
          <template #reference>
            <li
              class="nav_item nav_more_item"
              :class="{ active: activeItem >= showNum }"
            >
              <i
                class="font12 iconfont icon-icon_More"
                style="transform: scale(0.46)"
              />
            </li>
          </template>
          <ul class="nav_list_wrapper more_items">
            <template v-for="(navItem, index) in componentInfo.contentList">
              <custom-link
                v-if="index >= showNum"
                :key="navItem.id"
                :to="handleNavigate(navItem)"
                :target-type="navItem.targetType"
                :target-open-new-page="navItem.targetOpenNewPage"
              >
                <li
                  :key="navItem.id"
                  class="nav_color_item"
                  :class="{ active_color: index === componentInfo.activeIndex }"
                >
                  {{ navItem.navName }}
                </li>
              </custom-link>
            </template>
          </ul>
        </el-popover>
      </client-only>
    </ul>
    <div class="handle_bar">
      <index-header-box :component-info="componentInfo" />
    </div>
  </div>
</template>

<script setup>
import { handleNavigate } from '@/utils/utils'
import { getHearder } from '@/api/layouts'
import { useNavStore } from '@/stores/nav'
const store = useNavStore()

const router = useRouter()
const route = useRoute()
const showNum = ref(5)
const activeItem = ref(-1)
const isMore = ref(false)
const { data: componentInfo } = await useAsyncData('headers', async () => {
  try {
    let activeIndex = 0
    const data = await getHearder()
    if (data && data.contentList && data.contentList.length) {
      let is = false
      data.contentList.forEach((navItem, i) => {
        navItem.path = handleNavigate(navItem)
        if (navItem.path !== '/') {
          if (route.fullPath.indexOf(navItem.path) != -1) {
            activeIndex = i
            is = true
          }
        } else {
          if (route.path === navItem.path) {
            activeIndex = i
            is = true
          }
        }
      })
      if (!is) {
        activeIndex = -1
      }
    }
    data.activeIndex = activeIndex
    return data
  } catch (e) {
    console.error(e)
  }
})

store.setNav(componentInfo.value)

router.beforeResolve((to) => {
  if (componentInfo && componentInfo.value) {
    const contentList = componentInfo.value.contentList
    if (contentList && contentList.length) {
      let is = false
      contentList.forEach((e, i) => {
        if (e.path !== '/') {
          if (to.fullPath.indexOf(e.path) != -1) {
            activeItem.value = i
            is = true
          }
        } else {
          if (to.path === e.path) {
            activeItem.value = i
            is = true
          }
        }
      })
      if (!is) {
        activeItem.value = -1
      }
    }
  }
})
const showheadMoreBg = () => {
  isMore.value = true
}
const hideheadMoreBg = () => {
  isMore.value = false
}
</script>

<style lang="scss">
.nav_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
}
.nav_popover{
  padding: 0 !important;
  min-width: none !important;
}
  .nav_logo {
    width: 170px;
    height: auto;
    img {
      height: 60px;
      width: auto
    }
  }
  .nav_list_wrapper {
    flex: 1;
    display: flex;
    padding-left: 20px;
    color: #333333;

    .nav_item {
      display: flex;
      align-items: center;
      margin-right: 42px;
      color: #333;
      font-size: 18px;
      line-height: 40px;
      white-space: nowrap;
      .nav_item_more {
        min-width: 20px;
      }
      &:hover{
        cursor: pointer;
        position: relative;
        color: var(--el-color-primary);
      }
    }
    .nav_more_item {
      margin-right: 0px;
      margin-left: -16px;
    }
    .nav_color_item{
      height: 50px;
      padding: 15px 26px;
      font-size: 16px;
      color: #333333;
      white-space: nowrap;
      &:hover{
        cursor: pointer;
        position: relative;
        color: var(--el-color-primary);
      }
    }
    .nav_item:hover::after {
      content: " ";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 20px;
      height: 3px;
      border-radius: 1.5px;
      background-color: var(--el-color-primary);
    }
    .active {
      position: relative;
      color: var(--el-color-primary);
    }
    .active::after {
      content: " ";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 20px;
      height: 3px;
      border-radius: 1.5px;
      background-color: var(--el-color-primary);
    }
  }
  .more_items {
    flex-direction: column;
    padding-left: 0;
    .nav_item {
      justify-content: center;
      margin: 11px 0px;
      margin-right: 0;
    }
    .nav_item:first-child {
      margin-top: 3px;
    }
    .nav_item:last-child {
      margin-bottom: 3px;
    }
    .active_color {
      color: #2256f6;
    }
  }
  .handle_bar {
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 36px;
  }
</style>
